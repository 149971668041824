import {acceptHMRUpdate, defineStore} from "pinia";

export const useSEO = defineStore("seo", {
    state: () => {

        return {
            apiBase: "https://admin.fomin-kids.ru",
            cache: false,
            listSEO: []
        };
    },
    getters: {
        createApiUrl: (state) => (url) => {
            return `${state.apiBase}${url}`;
        },
        getSEO: (state) => (url) => {
            return state.listSEO.find(one => one.attributes.url === url).attributes.SEO
        },
        getSEOObj: (state) => (url) => {
            const SEO = state.listSEO.find(one => one.attributes.url === url)?.attributes?.SEO;
            if (SEO) {
                const SEOObj = {
                    title: SEO.SEOTitle,
                    ogTitle: SEO.SEOTitle,
                    description: SEO.SEODescription,
                    ogDescription: SEO.SEODescription,
                }

                if (SEO?.METAImage?.data?.id) {
                    SEOObj.ogImage = 'https://admin.fomin-kids.ru' + SEO?.METAImage?.data?.attributes?.url;
                    SEOObj.twitterCard = 'summary_large_image';
                } else {
                    SEOObj.ogImage = 'https://kids.fomin-clinic.ru/logo.jpg';
                    SEOObj.twitterCard = 'summary_large_image';
                }

                return SEOObj;
            }
            return {
                title: 'Клиника Фомина. Дети',
                ogTitle: 'Клиника Фомина. Дети',
                description: 'Сеть детских клиник нового поколения. Доказательная медицина, высокие технологии и лечение в игровой форме. Сочи, Москва, Санкт-Петербург, Краснодар, Уфа.',
                ogDescription: 'Сеть детских клиник нового поколения. Доказательная медицина, высокие технологии и лечение в игровой форме. Сочи, Москва, Санкт-Петербург, Краснодар, Уфа.',
                ogImage: 'https://kids.fomin-clinic.ru/logo.jpg',
                twitterCard: 'summary_large_image'
            }
        },
    },
    actions: {
        async fetchSEO() {
            const listSEO = await fetch(this.createApiUrl(`/api/seos?populate=deep`))
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));

            this.listSEO = [...listSEO];
        }
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSEO, import.meta.hot));
}
